import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import userAvatar from "./assets/img/yashikadp.png"; 
import Pusher from 'pusher-js';
import SuperChatComponentOne from './components/SuperChatComponentOne';
import SuperChatComponentTwo from './components/SuperChatComponentTwo';

function App() {
  const [comments, setComments] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const layoutType = searchParams.get('layout_type');

  useEffect(() => {
    const pusher = new Pusher("fcb1e37f9b72e444c361", {
      cluster: 'ap2',
      channelAuthorization: { endpoint: "https://backend.supchat.in/chat-auth/" } 
    });

    const channel = pusher.subscribe('private-user-112233');
    channel.bind('client-newmessagepinned', function(data) {
      console.log("data", data.message)
      setComments([data.message]);
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  return (
    <main className="w-full h-screen flex items-center justify-center font-plusJakartaSans">
      {comments.map((comment, index) => (
        layoutType === '2' ? 
        <SuperChatComponentTwo
          key={index}
          userAvatarSrc={comment.avatar || userAvatar}
          userName={comment.name}
          contributionAmount={comment.moneyContributed}
          message={comment.text}
        /> : 
        <SuperChatComponentOne
          key={index}
          userAvatarSrc={comment.avatar || userAvatar}
          userName={comment.name}
          contributionAmount={comment.moneyContributed}
          message={comment.text}
        />
      ))}
    </main>
  );
}

export default App;
