import React from 'react';

const SuperChatComponentOne = ({ userAvatarSrc, userName, contributionAmount, message }) => {
  return (
    <div className="flex gap-[10px] items-center">
      <div>
        <img src={userAvatarSrc} alt="profile avatar" className="w-[66px]" />
        <label
          htmlFor=""
          className="text-[13px] font-bold tracking-[-0.35px] w-[66px] h-[26px] bg-[#5156D4] rounded-[14px] flex items-center justify-center text-white -mt-[10px] relative z-10"
        >
          {contributionAmount}
        </label>
      </div>
      <div className="max-w-[354px] w-full p-[1px] bg-[linear-gradient(to_right_,#5156D4,#F1D15F,#EC6A5E)] rounded-xl box-border overflow-hidden">
        <div
          style={{ borderRadius: "inherit" }}
          className="sm:p-4 p-2 sm:pb-[11px] bg-[rgba(34,34,49,0.90)] rounded-xl"
        >
          <p className="text-white font-normal leading-[133%] text-[15px] font-plusjakarta">
            <span className="text-[#EC6A5E] font-semibold text-base tracking-[-0.35px]">
              {userName}
            </span>{" "}
            {message}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuperChatComponentOne;
