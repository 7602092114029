import React from 'react';

const SuperChatComponentTwo = ({ userAvatarSrc, userName, contributionAmount, message }) => {
  return (
    <div className="max-w-[457px] w-full p-[1px] bg-[linear-gradient(to_right_,#5156D4,#F1D15F,#EC6A5E)] rounded-[9px_90px_90px_28px] box-border overflow-hidden">
      <div
        style={{ borderRadius: "inherit" }}
        className="flex w-full h-full sm:gap-4 items-center sm:pr-6 sm:pl-4 sm:py-2 p-3 gap-2 bg-[rgba(34,34,49,0.90)]"
      >
        <div>
          <img src={userAvatarSrc} alt="img" className="w-[66px]" />
          <label
            htmlFor=""
            className="text-[13px] font-bold tracking-[-0.35px] w-[66px] h-[26px] bg-[#5156D4] rounded-[14px] flex items-center justify-center text-white -mt-[16px] relative z-10"
          >
            {contributionAmount}
          </label>
        </div>
        <p className="text-white font-normal leading-[133%] font-plusjakarta text-[15px]">
          <span className="text-[#EC6A5E] font-semibold text-base tracking-[-0.35px]">
            {userName}
          </span>{" "}
          {message}
        </p>
      </div>
    </div>
  );
};

export default SuperChatComponentTwo;
